import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyMenuLogin = lazy(
  () => import("./menu-login-tlW5wa-6.js").then((module) => ({
    default: module.MenuLogin
  }))
);
function MenuLogin({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyMenuLogin, { ...props });
}
const useMenuLoginContent = () => {
  return useMemo(
    () => ({
      menuLogin: {
        component: MenuLogin
      }
    }),
    []
  );
};
export {
  LazyMenuLogin as MenuLogin,
  useMenuLoginContent
};
